<template>
  <div
    class="kt-content kt-content--fit-top kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
    id="kt_content"
  >
    <!-- begin:: Content -->

    <!-- begin:: Hero -->
    <div class="kt-sc" style="background-image: url('assets/media/bg/bg-9.jpg')">
      <div class="kt-container">
        <div class="kt-sc__top">
          <h3 class="kt-sc__title">Help Center</h3>
          <div class="kt-sc__nav">
            <a href="#" class="kt-link kt-font-bold">Community</a>
            <a href="#" class="kt-link kt-font-bold">Visit Blog</a>
          </div>
        </div>
        <div class="kt-sc__bottom">
          <h3
            class="kt-sc__heading kt-heading kt-heading--center kt-heading--xxl kt-heading--medium"
          >How can we help?</h3>
          <form class="kt-sc__form">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1"></span>
              </div>
              <input
                type="text"
                class="form-control"
                placeholder="Ask a question"
                aria-describedby="basic-addon1"
              />
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- end:: Hero -->

    <!-- begin:: Section -->
    <div class="kt-container">
      <div class="row">
        <div class="col-lg-4">
          <a href="#" class="kt-portlet kt-iconbox kt-iconbox--animate-slow">
            <div class="kt-portlet__body">
              <div class="kt-iconbox__body">
                <div class="kt-iconbox__icon">
                  <i
                    v-bind:style="{ fontSize: '50px' }"
                    class="flaticon-presentation kt-font-success kt-font-big"
                  ></i>
                </div>
                <div class="kt-iconbox__desc">
                  <h3 class="kt-iconbox__title">Get Started</h3>
                  <div class="kt-iconbox__content">Base FAQ Questions</div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-4">
          <a href="#" class="kt-portlet kt-iconbox kt-iconbox--animate">
            <div class="kt-portlet__body">
              <div class="kt-iconbox__body">
                <div class="kt-iconbox__icon">
                  <i
                    v-bind:style="{ fontSize: '50px' }"
                    class="flaticon-computer kt-font-success kt-font-big"
                  ></i>
                </div>
                <div class="kt-iconbox__desc">
                  <h3 class="kt-iconbox__title">Tutorials</h3>
                  <div class="kt-iconbox__content">Books & Articles</div>
                </div>
              </div>
            </div>
          </a>
        </div>
        <div class="col-lg-4">
          <a href="#" class="kt-portlet kt-iconbox kt-iconbox--animate-fast">
            <div class="kt-portlet__body">
              <div class="kt-iconbox__body">
                <div class="kt-iconbox__icon">
                  <i
                    v-bind:style="{ fontSize: '50px' }"
                    class="flaticon-businesswoman kt-font-success kt-font-big"
                  ></i>
                </div>
                <div class="kt-iconbox__desc">
                  <h3 class="kt-iconbox__title">User Guide</h3>
                  <div class="kt-iconbox__content">Complete Knowledgebase</div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

    <!-- end:: iconbox -->

    <!-- begin:: accordions -->
    <div class="row">
      <div class="col-lg-6">
        <div class="kt-portlet kt-portlet--height-fluid">
          <div class="kt-portlet__body">
            <div class="kt-infobox">
              <div class="kt-infobox__header">
                <h2 class="kt-infobox__title">Buying Product</h2>
              </div>
              <div class="kt-infobox__body">
                <!--begin::Accordion-->
                <v-app>
                  <v-row justify="center">
                    <v-expansion-panels tile flat>
                      <v-expansion-panel v-for="(item,i) in 5" :key="i">
                        <v-expansion-panel-header>Item</v-expansion-panel-header>
                        <v-expansion-panel-content>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-app>

                <!--end::Accordion-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="kt-portlet kt-portlet--height-fluid">
          <div class="kt-portlet__body">
            <div class="kt-infobox kt-infobox--success">
              <div class="kt-infobox__header">
                <h2 class="kt-infobox__title">Installation</h2>
              </div>
              <div class="kt-infobox__body">
                <!--begin::Accordion-->
               <v-app>
                  <v-row justify="center">
                    <v-expansion-panels tile flat>
                      <v-expansion-panel v-for="(item,i) in 5" :key="i">
                        <v-expansion-panel-header>Item</v-expansion-panel-header>
                        <v-expansion-panel-content>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-app>


                <!--end::Accordion-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end:: accordions -->

    <!-- begin:: accordions -->
    <div class="row">
      <div class="col-lg-6">
        <div class="kt-portlet kt-portlet--height-fluid">
          <div class="kt-portlet__body">
            <div class="kt-infobox">
              <div class="kt-infobox__header">
                <h2 class="kt-infobox__title">User Roles</h2>
              </div>
              <div class="kt-infobox__body">
                <!--begin::Accordion-->
                 <v-app>
                  <v-row justify="center">
                    <v-expansion-panels tile flat>
                      <v-expansion-panel v-for="(item,i) in 5" :key="i">
                        <v-expansion-panel-header>Item</v-expansion-panel-header>
                        <v-expansion-panel-content>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-app>


                <!--end::Accordion-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="kt-portlet kt-portlet--height-fluid">
          <div class="kt-portlet__body">
            <div class="kt-infobox kt-infobox--success">
              <div class="kt-infobox__header">
                <h2 class="kt-infobox__title">Reports Generation</h2>
              </div>
              <div class="kt-infobox__body">
                <!--begin::Accordion-->
                <v-app>
                  <v-row justify="center">
                    <v-expansion-panels tile flat>
                      <v-expansion-panel v-for="(item,i) in 5" :key="i">
                        <v-expansion-panel-header>Item</v-expansion-panel-header>
                        <v-expansion-panel-content>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-row>
                </v-app>


                <!--end::Accordion-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end:: Section -->

    <!-- begin:: Section -->
    <div class="kt-container">
      <div class="kt-portlet">
        <div class="kt-portlet__body">
          <div class="kt-infobox">
            <div class="kt-infobox__header">
              <h2 class="kt-infobox__title">AirPlus SAAS License</h2>
            </div>
            <div class="kt-infobox__body">
              <div class="kt-infobox__section">
                <h3 class="kt-infobox__subtitle">Basic License</h3>
                <div class="kt-infobox__content">
                  asdasdasd.
                  <a href="#" class="kt-link">Read More</a>
                </div>
              </div>
              <div class="kt-infobox__section">
                <h3 class="kt-infobox__subtitle">Extended License</h3>
                <div class="kt-infobox__content">
                  asdasdasd.
                  <a href="#" class="kt-link">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end:: Section -->

    <!-- begin:: Section -->
    <div class="kt-container">
      <div class="row">
        <div class="col-lg-6">
          <div class="kt-portlet kt-callout">
            <div class="kt-portlet__body">
              <div class="kt-callout__body">
                <div class="kt-callout__content">
                  <h3 class="kt-callout__title">Get in Touch</h3>
                  <p
                    class="kt-callout__desc"
                  >Windows 10 automatically installs updates to make for sure</p>
                </div>
                <div class="kt-callout__action">
                  <a
                    href="custom/apps/support-center/feedback.html"
                    class="btn btn-custom btn-bold btn-upper btn-font-sm btn-primary"
                  >Submit a Request</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="kt-portlet kt-callout">
            <div class="kt-portlet__body">
              <div class="kt-callout__body">
                <div class="kt-callout__content">
                  <h3 class="kt-callout__title">Live Chat</h3>
                  <p
                    class="kt-callout__desc"
                  >Windows 10 automatically installs updates to make for sure</p>
                </div>
                <div class="kt-callout__action">
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#kt_chat_modal"
                    class="btn btn-custom btn-bold btn-upper btn-font-sm btn-success"
                  >Start Chat</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- end:: Section -->

    <!-- end:: Content -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "support",
  data() {
    return {
      data: {}
    };
  },
  methods: {},
  computed: {},
  mounted() {
    this.getBillings();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ivoice", route: "dashboard" }
    ]);
  }
};
</script>